import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaGhost } from 'react-icons/fa';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const ButtonWrapper = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background-color: #ff6600;
  color: #ffffff;
  text-decoration: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 30px;
  }

  &:hover {
    background-color: #ff8533;
    box-shadow: 0 0 15px rgba(255, 102, 0, 0.7);
  }

  svg {
    margin-right: 4px;
    font-size: 0.9em;

    @media (min-width: 768px) {
      margin-right: 8px;
      font-size: 1em;
    }
  }
`;

const HalloweenButton = () => {
  const endDate = new Date('2024-10-31T23:59:59');
  const now = new Date();

  if (now > endDate) {
    return null;
  }

  return (
    <ButtonWrapper to="/offer">
      <FaGhost />
      Offerta Spettrale
    </ButtonWrapper>
  );
};

export default HalloweenButton;