import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const brandColor = '#3a0b3e';
export const secondaryBrandColor = '#1686ea'; // Light blue color from the logo
// Theme colors
export const themeColors = {
  standard: {
    primary: '#007bff',
    secondary: '#6c757d',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    text: '#333',
    highlight: '#28a745'
  },
  blackfriday: {
    primary: '#e74c3c',
    secondary: '#34495e',
    background: 'linear-gradient(135deg, #141e30 0%, #243b55 100%)',
    text: '#ecf0f1',
    highlight: '#3498db'
  },
  christmas: {
    primary: '#c41e3a',
    secondary: '#165b33',
    background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
    text: '#ffffff',
    highlight: '#ffd700'
  },
  halloween: {
    primary: '#ff6600',
    secondary: '#1a1a1a',
    background: 'linear-gradient(135deg, #ff9966 0%, #ff5e62 100%)',
    text: '#ffffff',
    highlight: '#4a0e4e'
  }
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --brand-color: ${themeColors.standard.primary};
    --brand-color-light: #4a0e4e;
    --brand-color-dark: #2a082e;
    --secondary-brand-color: ${themeColors.standard.secondary};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const PageContainer = styled.div`
  // ... stili per il container della pagina
`;

export const PageTitle = styled.h1`
  // ... stili per il titolo della pagina
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
`;
