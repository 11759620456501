import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';
import { brandColor, secondaryBrandColor, themeColors } from '../styles/globalStyles';
import HalloweenButton from './halloween_btn';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 1000;
  background-color: ${props => props.scrolled ? 'white' : 'transparent'};
  color: ${props => props.scrolled ? brandColor : 'white'};
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    img {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }
`;

const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${props => props.scrolled ? 'white' : brandColor};
    padding: 20px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: inherit;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    scrollToElement(sectionId, {
      offset: -100,
      ease: 'out-expo',
      duration: 1500
    });
    setIsMenuOpen(false);
  };

  return (
    <HeaderWrapper scrolled={scrolled}>
      <Nav>
        <LogoSection>
          <Logo to="/">
            <img
              src="/images/logo.jpeg"
              alt="Logo"
              width="40"
              height="40"
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
                transition: 'box-shadow 0.3s ease'
              }}
            />
            <LogoText>CodeDaVinci</LogoText>
          </Logo>
        </LogoSection>
        <CenterSection>
          <HalloweenButton />
        </CenterSection>
        <NavLinks isOpen={isMenuOpen} scrolled={scrolled}>
          <NavLink onClick={toggleMenu} as={Link} to="/sales" style={{
            backgroundColor: '#ffffff',
            color: 'black',
            padding: '5px 15px',
            borderRadius: '50px',
            transition: 'background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease',
            position: 'relative',
            overflow: 'hidden',
            border: '2px solid transparent', // Aggiunto per il bordo multicolor
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <span style={{
              display: 'inline-block',
              marginRight: '8px'
            }}>
              {/* SVG multicolor dello store */}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="storeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: 'red', stopOpacity: 1 }} />
                    <stop offset="50%" style={{ stopColor: 'blue', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'green', stopOpacity: 1 }} />
                  </linearGradient>
                </defs>
                <path d="M21.026,6.105a3.1,3.1,0,0,1-2.365,3.848A3.031,3.031,0,0,1,15.1,7.222l-.042-.5A3.03,3.03,0,0,1,12.041,10h-.082A3.03,3.03,0,0,1,8.94,6.719l-.031.375a3.121,3.121,0,0,1-2.83,2.9A3.03,3.03,0,0,1,2.941,6.236l.87-3.479A1,1,0,0,1,4.781,2H19.219a1,1,0,0,1,.97.757ZM18.121,12A5.021,5.021,0,0,0,20,11.631V21a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11.631a4.914,4.914,0,0,0,4.907-.683A5.131,5.131,0,0,0,12.042,12a5.027,5.027,0,0,0,3.051-1.052A4.977,4.977,0,0,0,18.121,12ZM14,17a2,2,0,0,0-4,0v3h4Z" fill="url(#storeGradient)"/>
              </svg>
            </span>
            <span style={{
              background: 'linear-gradient(45deg, red, blue, green)', // Testo multicolor
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold' // Aggiunto per rendere il testo più evidente
            }}>
              Prezzi
            </span>
          </NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#servizi';
            } else {
              scrollToSection('#servizi');
            }
          }}>Servizi</NavLink>
          {/* <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#metodo';
            } else {
              scrollToSection('#metodo');
            }
          }}>Metodo</NavLink> */}
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#exp';
            } else {
              scrollToSection('#exp');
            }
          }}>Exp</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#portfolio';
            } else {
              scrollToSection('#portfolio');
            }
          }}>Portfolio</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#recensioni';
            } else {
              scrollToSection('#recensioni');
            }
          }}>Recensioni</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#faq';
            } else {
              scrollToSection('#faq');
            }
          }}>FAQ</NavLink>

        </NavLinks>
        <HamburgerButton onClick={toggleMenu}>
          ☰
        </HamburgerButton>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
