export const companyData = {
  tel_label: '+39 366 424 8926',
  tel: '+393664248926',
  whatsapp: '393664248926',
  email: 'codedavinci.software@gmail.com',
  websiteLink: {
    v_foti: 'https://vincenzo-foti.it',
  },
  socialLinks: {
    linkedin: 'https://www.linkedin.com/company/codedavinci/',
    instagram: 'https://www.instagram.com/codedavincidev/',
    threads: 'https://www.threads.net/@codedavincidev',
    twitter: 'https://x.com/CodeDaVinciDev'
  },
  trustpilot: 'https://it.trustpilot.com/review/codedavinci.com',
  piva: '02746190749'
};

export const brandColor = '#your-brand-color-here'; // Replace with your actual brand color

// Add any other global constants or environment variables here

