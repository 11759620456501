import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { motion } from 'framer-motion';
import { PageTitle, Paragraph, brandColor, secondaryBrandColor } from '../styles/globalStyles';
import { FaSpider, FaGhost, FaSkull, FaCandyCane, FaMoon, FaLock, FaMobile, FaSearch, FaGlobe, FaClock, FaHeadset, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

const flashAnimation = keyframes`
  0%, 100% { box-shadow: none; }
  50% { box-shadow: 0 0 100px 20px rgba(255, 255, 255, 0.8) inset; }
`;

const OfferWrapper = styled.div`
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #1a1a1a;
  color: #f0f0f0;
  position: relative;
  overflow: hidden;
  animation: ${props => props.flash ? flashAnimation : 'none'} 0.2s;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroSection = styled.section`
  text-align: center;
  margin-bottom: 40px;
`;

const HalloweenTitle = styled(PageTitle)`
  color: #ff6600;
  text-shadow: 2px 2px 4px #000000;
  font-family: 'Creepster', cursive;
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const OfferCard = styled(motion.div)`
  background-color: rgba(42, 42, 42, 0.8);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(255, 102, 0, 0.3);
  padding: 30px;
  transition: all 0.3s ease;
  text-align: center;
  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    padding: 40px;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 48px rgba(255, 102, 0, 0.5);
  }
`;

const OfferTitle = styled.h3`
  font-size: 2rem;
  color: #ff6600;
  margin-bottom: 20px;
  font-family: 'Creepster', cursive;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const OfferDescription = styled.p`
  font-size: 1.1rem;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const OldPrice = styled.span`
  font-size: 1.5rem;
  color: #888;
  text-decoration: line-through;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 2rem;
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const NewPrice = styled.span`
  font-size: 2rem;
  color: #ff6600;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const OfferButton = styled.a`
  background-color: #ff6600;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    background-color: #ff8533;
    transform: scale(1.05);
  }
`;

const Countdown = styled.div`
  font-size: 1.2rem;
  margin-top: 40px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const FloatingIcon = styled(motion.div)`
  position: absolute;
  font-size: 1.5rem;
  color: #ff6600;
  opacity: 0.5;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const HalloweenList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const HalloweenListItem = styled.li`
  display: flex;
  align-items: center;
  background-color: rgba(255, 102, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(255, 102, 0, 0.3);
  }

  svg {
    color: #ff6600;
    margin-right: 10px;
    font-size: 1.5em;
  }
`;

const ListItemText = styled.span`
  color: #f0f0f0;
  font-size: 1rem;
`;

const AudioButton = styled.button`
  position: fixed;
  bottom: 60px;
  left: 10px;
  background-color: rgba(255, 102, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 102, 0, 1);
  }
`;

const Offer = () => {
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);  // Impostato su true di default

  const initializeAudio = () => {
    if (audioRef.current && !audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaElementSource(audioRef.current);
      const analyser = audioContextRef.current.createAnalyser();
      
      source.connect(analyser);
      analyser.connect(audioContextRef.current.destination);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const checkAudio = () => {
        analyser.getByteFrequencyData(dataArray);
        const thunderThreshold = 200;
        
        if (dataArray.some(value => value > thunderThreshold)) {
          setIsFlashing(true);
          setTimeout(() => setIsFlashing(false), 200);
        }
        
        requestAnimationFrame(checkAudio);
      };

      checkAudio();
    }
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(console.error);
        initializeAudio();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    // Tentativo di riproduzione automatica
    if (audioRef.current) {
      audioRef.current.play()
        .then(() => {
          initializeAudio();
        })
        .catch((error) => {
          console.log("Autoplay was prevented. User interaction is required to play audio.");
          setIsPlaying(false);
        });
    }

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const currentDate = new Date();
  const endDate = new Date('2024-10-31');
  const timeLeft = endDate - currentDate;
  const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));

  const offerFeatures = [
      { icon: <FaGlobe />, text: "Sito WEB" },
      { icon: <FaMobile />, text: "Design responsive" },
    { icon: <FaLock />, text: "Security" },
    { icon: <FaSearch />, text: "Ottimizzazione SEO" },
    { icon: <FaClock />, text: "Pronto in 7 giorni" },
    { icon: <FaHeadset />, text: "Assistenza gratuita entro 48h" },
  ];

  const handleWhatsAppClick = () => {
    const phoneNumber = "+393664248926";
    const message = encodeURIComponent("L'Offerta da Paura non mi fa paura arrivo!");
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <OfferWrapper flash={isFlashing}>
      <audio ref={audioRef} loop>
        <source src="/assets/mp3/halloween_sound.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <ContentContainer>
        <HeroSection>
          <HalloweenTitle>Offerta Spettrale di Halloween!</HalloweenTitle>
          <Paragraph>
            Approfitta della nostra offerta da brivido! Solo fino al 31 ottobre 2024, 
            potrai avere il tuo sito web a un prezzo da paura!
          </Paragraph>
        </HeroSection>
        <OfferCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <OfferTitle>Pacchetto Web Spettrale</OfferTitle>
          <OfferDescription>
            Un sito web che farà resuscitare il tuo business! Include:
          </OfferDescription>
          <HalloweenList>
            {offerFeatures.map((feature, index) => (
              <HalloweenListItem key={index}>
                {feature.icon}
                <ListItemText>{feature.text}</ListItemText>
              </HalloweenListItem>
            ))}
          </HalloweenList>
          <PriceContainer>
            <OldPrice>€500</OldPrice>
            <NewPrice>€220 + IVA</NewPrice>
          </PriceContainer>
          <OfferButton onClick={handleWhatsAppClick}>Approfitta Ora!</OfferButton>
        </OfferCard>
        <Countdown>
          {daysLeft > 0 ? `${daysLeft} giorni rimasti per questa offerta da paura!` : "L'offerta è scaduta!"}
        </Countdown>
      </ContentContainer>
      <FloatingIcon
        as={FaSpider}
        initial={{ x: '-100%', y: '50%' }}
        animate={{ x: '100%', y: '30%' }}
        transition={{ repeat: Infinity, duration: 20, ease: 'linear' }}
        style={{ top: '20%', left: 0 }}
      />
      <FloatingIcon
        as={FaGhost}
        initial={{ x: '100%', y: '0%' }}
        animate={{ x: '-100%', y: '100%' }}
        transition={{ repeat: Infinity, duration: 15, ease: 'linear' }}
        style={{ top: '10%', right: 0 }}
      />
      <FloatingIcon
        as={FaCandyCane}
        initial={{ x: '50%', y: '-100%' }}
        animate={{ x: '30%', y: '100%' }}
        transition={{ repeat: Infinity, duration: 18, ease: 'linear' }}
        style={{ left: '20%', top: 0 }}
      />
      <FloatingIcon
        as={FaSkull}
        initial={{ x: '-50%', y: '100%' }}
        animate={{ x: '80%', y: '-100%' }}
        transition={{ repeat: Infinity, duration: 25, ease: 'linear' }}
        style={{ right: '15%', bottom: 0 }}
      />
      <FloatingIcon
        as={FaMoon}
        initial={{ x: '0%', y: '50%' }}
        animate={{ x: '100%', y: '0%' }}
        transition={{ repeat: Infinity, duration: 12, ease: 'linear' }}
        style={{ left: '10%', bottom: '20%' }}
      />
      <AudioButton onClick={toggleAudio}>
        {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
      </AudioButton>
    </OfferWrapper>
  );
};

export default Offer;